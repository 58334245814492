<template>
  <div class="myLesson">
    <!-- tab-container -->
    <div class="container">
      <div class="list">
        <router-link
          :to="info.isoffline == 1 ? `/lesson/offline/${info.lessonid}` : `/lesson/onlineDetail/${info.lessonid}`"
        >
          <h4 class="ell">{{ info.bookname }}</h4>
          <div class="top">
            <img :src="info.images" />
            <div>
              <p>订单编号：{{ info.order_id }}</p>
              <p>
                订单状态：
                <span class="gold-num">{{ info.status_name }}</span>
              </p>
              <p>下单时间：{{ info.createTime }}</p>
            </div>
          </div>
        </router-link>
        <div class="price" v-show="info.isoffline == 1">
          <p>
            <span>订单总额：</span>
            <em class="gold-num">{{ info.price }}</em>
          </p>
          <p>
            <span>站点：</span>
            <em>{{ info.alias }}</em>
          </p>
          <p>
            <span>讲师：</span>
            <em>{{ info.teacher_name }}</em>
          </p>
          <p>
            <span>听课地点：</span>
            <em>{{ info.address }}</em>
          </p>
          <p>
            <span>开课时间：</span>
            <em>{{ info.starttime }}</em>
          </p>
          <div class="btn">
            <button
              v-show="info.is_question && isVisible"
              @click="btnMethods('question', info.lessonid)"
            >问卷调查</button>
            <button
              v-show="info.is_modify && isVisible"
              @click="btnMethods('change', info.id)"
            >修改听课地点</button>
            <button
              v-show="info.status == 0 && isVisible"
              class="gold"
              @click="btnMethods('pay', info.order_id)"
            >去付款</button>
            <button
              v-show="info.status == 0 || info.status == -1"
              @click="btnMethods('del', info.order_id)"
            >删除订单</button>
            <button
              v-show="info.status == 0 && isVisible"
              @click="btnMethods('cancel', info.order_id)"
            >取消订单</button>
          </div>
        </div>
        <div class="person">
          <ul>
            <li v-for="(item, index) in offlist" :key="index">
              <p v-if="!item.status" @click="item.status = true">
                <span>听课人:</span>
                <em>{{ item.name }}</em>
                <i class="jym-icon-test59 icon"></i>
                <i
                  v-show="item.status > 0"
                  class="jym-qrqcode"
                  @click="popupVisible = true; imgSrc = item.qrcode"
                ></i>
              </p>
              <p v-else @click="item.status = false">
                <span>听课人:</span>
                <em>{{ item.name }}</em>
                <i class="jym-icon-test58 icon"></i>
              </p>
              <div v-if="item.status">
                <p>
                  <span>电话:</span>
                  <em>{{ item.tel }}</em>
                </p>
                <p>
                  <span>证件号:</span>
                  <em>{{ item.cert_num }}</em>
                </p>
                <p v-show="info.status > 0">
                  <span>状态:</span>
                  <em>{{ item.sign_name }}</em>
                </p>
                <p v-show="info.status > 0">
                  <span>签到码:</span>
                  <em>{{ item.signNum }}</em>
                </p>
                <p v-show="info.status > 0 && item.isrefund == 0 && item.issign == 0">
                  <span>二维码:</span>
                  <em
                    class="i-btn"
                    @click="popupVisible = true; imgSrc = item.qrcode; lessonPerson = item.name"
                  >
                    <i class="jym-qrqcode"></i>显示二维码
                  </em>
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <mt-popup v-model="popupVisible" position="center">
      <i class="dialog-close jym-close" @click="popupVisible = false"></i>
      <img :src="imgSrc" />
      <p style="margin-bottom: 0.3rem; text-align: center;font-size: .26rem;">听课人：{{ lessonPerson }}</p>
    </mt-popup>

    <mt-popup v-model="siteVisible" position="center">
      <div class="site">
        <ul>
          <li v-for="(item, index) in siteList" :key="index">
            <input
              type="radio"
              class="check_box tui-checkbox position"
              :id="'id'+item.id"
              :value="item.id"
              v-model="addressId"
            />
            <p>
              <strong>{{ item.alias }}</strong>
            </p>
            <p>开课地点：{{ item.address }}</p>
            <p>开课时间：{{ item.start_time }}</p>
            <p>剩余席位：{{ item.num - item.make_num }}</p>
          </li>
        </ul>

        <button class="btn btn-black" @click="submitSite(info.id)">确定修改</button>
      </div>
    </mt-popup>
  </div>
</template>

<script>
import { MessageBox } from "mint-ui";
import { orderDetail, orderCancel, orderDel } from "@api/public";
import { orderSite, orderSiteSave } from "@api/user";
export default {
  data() {
    return {
      order_id: "",
      info: {},
      offlist: [],
      popupVisible: false,
      siteVisible: false,
      isVisible: true,
      imgSrc: "",
      siteList: [],
      addressId: "",
      lessonPerson: "",
    };
  },
  watch: {
    addressId(val) {
      this.addressId = val;
    },
  },
  created() {
    this.order_id = this.$route.params.id;
    this.getList();
  },
  methods: {
    // 获取列表数据
    getList() {
      let that = this;

      orderDetail(that.order_id).then((res) => {
        that.info = res.data;
        that.offlist = res.data.offlist;
        that.offlist.forEach((item, i) => {
          that.$set(that.offlist, i, Object.assign({}, item, { status: true }));
        });
      });
    },

    // 获取听课地点
    orderSite(id) {
      orderSite(id)
        .then((res) => {
          if (res.data.length > 0) {
            this.siteVisible = true;
            this.siteList = res.data;
          } else {
            this.siteVisible = false;
            MessageBox("提示", "暂无其他听课地点");
          }
        })
        .catch((err) => {
          MessageBox("提示", err.msg);
        });
    },

    // 修改地址
    submitSite(id) {
      let that = this;
      if (that.addressId) {
        orderSiteSave(id, that.addressId)
          .then(() => {
            that.siteVisible = false;
            that.$dialog.success("修改成功!");
            setTimeout(() => {
              that.$router.go(0);
            }, 2000);
          })
          .catch((err) => {
            MessageBox("提示", err.msg);
          });
      } else {
        that.$dialog.toast({ mes: "请选择听课地点" });
      }
    },

    // 订单操作
    btnMethods(status, order_id) {
      let that = this;
      switch (status) {
        case "question":
          if(that.info.is_question_type == 0) {
            that.$router.push({
            path: "/user/question",
            query: { id: order_id },
            });
          }
           if(that.info.is_question_type == 1) {
            that.$router.push({
            path: "/user/questionPublic",
            query: { id: order_id },
            });
          }
        if(that.info.is_question_type == 2) {
            that.$router.push({
                path: "/user/questionBefore",
                query: { id: order_id },
            });
        }
          
          break;
        case "pay":
          that.$router.push({
            path: "/lesson/pay",
            query: { order_id: order_id },
          });
          break;
        case "del":
          orderDel(order_id)
            .then((res) => {
              that.$dialog.success(res.msg);
              setTimeout(() => {
                that.$router.go(-1);
              }, 500);
            })
            .catch((err) => {
              that.$dialog.error(err.msg);
            });
          break;
        case "cancel":
          orderCancel(order_id)
            .then((res) => {
              that.$dialog.success(res.msg);
              that.isVisible = false;
            })
            .catch((err) => {
              that.$dialog.error(err.msg);
            });
          break;
        case "change":
          that.orderSite(order_id);
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.myLesson {
  padding-bottom: 1rem;
  .container {
    background: white;
    width: 7.1rem;
    margin: 0.2rem 0.2rem 0;
    border-radius: 0.12rem;
    .list {
      width: 100%;
      h4 {
        font-size: 0.28rem;
        padding: 0.2rem 0 0.1rem 0.1rem;
      }
      .top {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ddd;
        img {
          width: 2.6rem;
          height: 1.6rem;
          padding: 0.1rem;
        }
        div {
          display: flex;
          flex-flow: column;
          justify-content: space-between;
          padding: 0.1rem;
          width: 4.5rem;
          p {
            font-size: 0.26rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #656565;
          }
        }
      }
      .price {
        padding: 0.2rem 0 0.1rem;
        border-bottom: 1px solid #ddd;
        p {
          font: 0.26rem/0.4rem "microsoft yahei";
          overflow: hidden;
          margin-bottom: 0.1rem;
          span {
            float: left;
            width: 1.6rem;
            text-align: right;
          }
          em {
            float: right;
            width: 5.3rem;
            padding: 0 0.2rem 0 0.1rem;
          }
        }
      }
      .person {
        padding: 0.2rem 0.3rem;
        ul li {
          width: 100%;
          height: auto;
          margin-bottom: 0.2rem;
          padding-bottom: 0.1rem;
          border-bottom: 1px dashed #ddd;
          p {
            overflow: hidden;
            margin-bottom: 0.1rem;
            font: 0.26rem/0.4rem "microsoft yahei";
            span {
              float: left;
              width: 1.1rem;
              text-align: right;
            }
            em {
              float: left;
              padding-left: 0.2rem;
              width: 4.2rem;
            }
            i.icon {
              float: right;
            }
            .i-btn {
              width: auto;
              margin-left: 0.2rem;
              padding: 0 0.2rem;
              font-size: 0.2rem;
              background: #daa070;
              color: white;
              border-radius: 1rem;
              i.jym-qrqcode {
                font-size: 0.28rem;
                margin-right: 0.06rem;
              }
            }
          }
        }
      }
      .btn {
        width: 100%;
        min-height: 0.2rem;
        overflow: hidden;
        padding: 0 0.1rem;
        button {
          float: right;
          height: 0.5rem;
          font-size: 0.24rem;
          border: 0.02rem solid #a9a9a9;
          margin: 0.15rem 0.1rem;
          border-radius: 0.24rem;
          padding: 0 0.2rem;
        }
        .gold {
          border: none;
          background: black;
          color: white;
        }
      }
    }
  }
  .site {
    width: 6.5rem;
    padding: 0.3rem 0.3rem 0.1rem;
    font-size: 0.28rem;
    max-height: 8rem;
    position: relative;
    overflow: scroll;
    ul {
      padding-bottom: 1rem;
      li {
        padding: 0.2rem 0;
        border-bottom: 1px dashed #ddd;
        position: relative;
        p {
          line-height: 0.42rem;
          padding-right: .5rem;
        }
      }
    }
    .btn {
      font-size: 0.28rem;
      color: #fff;
      width: 5.9rem;
      height: 0.8rem;
      border-radius: 0.1rem;
      text-align: center;
      line-height: 0.8rem;
      margin-top: 0.3rem;
      position: fixed;
      bottom: 0.2rem;
    }
  }
}
</style>